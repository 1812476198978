
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CcsMultiselect from "@/components/ccs-multiselect/index.vue";
import { getRoles } from "@/core/data/role";
export default defineComponent({
  name: "RoleMultiselect",
  components: {
    CcsMultiselect,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: false,
    },
    selectedValue: {
      required: false,
    },
    loading: {
      required: false,
    },
    isEdit: {
      required: false,
      defualt: true,
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const reRender = ref<any>(true);
    const selectedItem = ref<any>(props.modelValue);
    const roleList = ref<Array<any>>([]);
    const roleSelectedValue = ref<any>(props.selectedValue);
    const roleListComputed = computed(() => {
      return roleList.value.map((item) => ({
        ...item,
        value: item.id,
      }));
    });
    const searchRole = (name = "") => {
      return new Promise((res) => {
        getRoles(1, {name}).then((resObj: any) => {
          roleList.value[0] = { ...roleSelectedValue.value };

          roleList.value.splice(
            !roleList.value[0].id ? 0 : 1,
            roleList.value.length,
            ...resObj.data.filter((item) => item.id != roleList.value[0].id)
          );
          roleList.value = [...roleList.value];
          console.log('roleList',roleList.value);
          res(true);
        });
      });
    };

    const selectRole = (data) => {
      roleSelectedValue.value = data;
      emit("update:modelValue", roleSelectedValue.value?.id);
    };
    watch(
      () => props.isEdit,
      (newVal: any, oldVal) => {
        searchRole().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    watch(
      () => props.modelValue,
      (newVal: any, oldVal) => {
        selectedItem.value = props.modelValue;
        reRender.value = false;
        setTimeout(() => {
          reRender.value = true;
        }, 100);
      }
    );
    watch(
      () => props.selectedValue,
      (newVal: any, oldVal) => {
        roleSelectedValue.value = props.selectedValue;
        searchRole().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    onMounted(() => {
      searchRole();
    });
    return {
      selectedItem,
      roleListComputed,
      searchRole,
      selectRole,
      reRender,
    };
  },
});
