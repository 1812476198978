import moment from "moment";
import ApiService from "../services/ApiService";
interface Role {
  id: number;
  name: string;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const Roles: Array<Role> = [];

const deleteRole = function (item: Role) {
  return ApiService.delete(`role/${item.id}/`);
};

const addRole = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  for (const key in item) {
    formData.append(key, item[key]);
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`role/${id}/`, formData);
  } else {
    return ApiService.post("role/", formData);
  }
};

const exportRoleData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/role/?role_id=" + ids,
      "role-data"
    ).then((data) => res(data));
  });
};
const exportPDFRoles = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/role/?role_id=" + ids).then((data) =>
      res(data)
    );
  });
};
const roleObjClone = function () {
  return {
    id: 0,
    name: "",
    created_by: "",
  };
};
const jsonToInterface = function (item: any) {
  const newItem: Role = roleObjClone();
  newItem.id = item.id;
  newItem.name = item.name;
  newItem.created_by = item.created_by;
  return newItem;
};
const getRoleById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`role/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getRoles = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`role/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const jsonToInterfaceRole = jsonToInterface;
const getEmptyObjOfRole = roleObjClone;
export {
  Role,
  getRoles,
  deleteRole,
  addRole,
  getRoleById,
  jsonToInterfaceRole,
  roleObjClone,
  exportRoleData,
  exportPDFRoles,
  getEmptyObjOfRole
};

export default Roles;
